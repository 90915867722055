import { useRevalidator } from "@remix-run/react";
import type { Session } from "@supabase/supabase-js";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSupabaseClient } from "~/contexts/SupabaseClientContext/SupabaseClientContext";

export const useClientSession = () => {
  const { supabaseClient } = useSupabaseClient();
  const [session, setSession] = useState<Session | null>(null);
  const { revalidate } = useRevalidator();

  useEffect(() => {
    const {
      data: { subscription },
    } = supabaseClient.auth.onAuthStateChange((_event, _newSession) => {
      if (sessionsDifferenceRequireRevalidate(session, _newSession)) {
        setSession(_newSession);
        revalidate();
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [session, supabaseClient.auth, revalidate]);

  return { session };
};

const s = (session: Session | null) => {
  return _.pick(session, ["user.id"]);
};
const sessionsDifferenceRequireRevalidate = (
  existingSession: Session | null,
  newSession: Session | null
) => !_.isEqual(s(existingSession), s(newSession));
